<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
      :handle-download="downloadPdf"
      :handle-download-lock="downloadLockDocument"
      :download-loading="loadingDownload"
      :download-lock-loading="loadingDownloadLock"
      :enable-submit="!item.frozen"
      :enable-reset="!item.frozen"
      :enable-edit="!item.frozen"
      :enable-download-lock="!item.frozen && item.positions.length > 0"
      :enable-delete="!item.frozen"
      :handle-copy="copy"
    />
    <OfferForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      :handle-message="showMessage"
    />
    <Loading :visible="offerIsLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {createHelpers} from "vuex-map-fields";
import OfferForm from "../../components/offer/Form";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import UpdateMixin from "@/mixins/UpdateMixin";
import DocumentMixin from "@/mixins/DocumentMixin";
import OfferMixin from "@/mixins/OfferMixin";

const servicePrefix = "Offer";

const {mapFields} = createHelpers({
  getterType: "offer/getField",
  mutationType: "offer/updateField",
});

export default {
  name: "OfferUpdate",
  servicePrefix,
  mixins: [UpdateMixin, OfferMixin, DocumentMixin],
  components: {
    Loading,
    Toolbar,
    OfferForm,
  },
  data() {
    return {
      item: {
        originalUpdateMessage: this.updateMessage,
        positions: [],
      },
      doFreeze: false,
    };
  },
  computed: {
    ...mapFields(["error", "isLoading", "updated", "violations", "handled", "handleAction"]),
    ...mapGetters("offer", ["find"]),
    currentDocument() {
      if (!this.item.document) {
        return null;
      }
      let document = this.resolveDocument(this.item.document);
      if (typeof document === "undefined") {
        return null;
      }
      return document;
    },
    offerIsLoading() {
      return this.isLoading || this.loadingDownload || this.loadingDownloadLock;
    },
  },
  methods: {
    ...mapActions("offer", {
      deleteItem: "del",
      retrieve: "load",
      update: "update",
      actionOffer: "action",
    }),
    getItemSubs() {
      return {
        profile: {},
      };
    },
    downloadPdf() {
      if (this.item.frozen) {
        this.getAndDownloadDocument(this.item.document);
        return;
      }
      this.updateMessage = "Dokument generiert";
      this.generateOfferDocument(this.item);
      this.loadingDownload = true;
    },
    downloadLockDocument() {
      this.$confirm(this.$t('Are you sure you want persist and lock? Not reversible!'), {
        title: this.$t('Persist and lock?'),
        color: 'error',
        buttonTrueText: this. $t('Generate & Download'),
        buttonFalseText: this.$t('Cancel'),
      }).then((response) => {
        if (!response) {
          return;
        }
        this.doFreeze = true;
        this.generateOfferDocument(this.item);
        this.loadingDownloadLock = true;
      });
    },
    copy() {
      this.$confirm('Angebot jetzt kopieren?', {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No'),
      }).then((response) => {
        if (!response) {
          return;
        }
        this.actionOffer({item: this.item, action: "copy"});
      });
    },
  },
  watch: {
    currentDocument() {
      if ((this.loadingDownload || this.loadingDownloadLock) && this.currentDocument !== null) {
        this.getAndDownloadDocument(this.item.document).then(() => {
          this.loadingDownload = false;
          this.loadingDownloadLock = false;
          this.updateMessage = this.originalUpdateMessage;
        });
      }
    },
    handled() {
      if (this.handleAction === 'copy') {
        this.showMessage(this.$t('OfferCopied'));
        this.$router.push({
          name: "OfferUpdate",
          params: {id: this.handled["@id"]},
        });
        this.handleAction = null;
        this.handled = null;
      } else if (this.handleAction === 'generate') {
        this.item = { ...this.handled };
        if (this.doFreeze) {
          this.doFreeze = false;
          this.freezeDocument(this.handled).then(() => {
            this.$router.push({
              name: 'OfferList'
            });
          });
        }
        this.handleAction = null;
        this.handled = null;
      }
    },
  },
};
</script>
