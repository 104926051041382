import NotificationMixin from './NotificationMixin';
import { formatDateTime } from '@/utils/dates';

export default {
  mixins: [NotificationMixin],
  data() {
    return {
      retrieved: null,
      item: {},
      updateMessage: null,
      disableUpdateMessage: false,
      updateRoute: null,
    };
  },
  async created() {
    await this.loadObjectToUpdate();
  },
  beforeDestroy() {
    this.reset();
  },
  computed: {
  },
  methods: {
    del() {
      this.deleteItem(this.retrieved).then(() => {
        this.showMessage(`${this.item['@id']} deleted.`);
      });
    },
    formatDateTime,
    reset() {
      this.$refs.updateForm.$v.$reset();
    },
    onSendForm() {
      if (this.isValidForm()) {
        this.update(this.$refs.updateForm.$v.item.$model);
      }
    },
    isValidForm() {
      const updateForm = this.$refs.updateForm;
      updateForm.$v.$touch();
      return !updateForm.$v.$invalid;
    },
    resetForm() {
      this.$confirm('Formular zurücksetzen?', {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No'),
      }).then((response) => {
        if (!response) {
          return;
        }
        this.doResetForm();
      });
    },
    doResetForm(){
      this.$refs.updateForm.$v.$reset();
      this.item = { ...this.retrieved };
    },
    async loadObjectToUpdate(){
      await this.retrieve(decodeURIComponent(this.$route.params.id));
      this.retrieved = this.find(decodeURIComponent(this.$route.params.id));
    },
  },
  watch: {
    deleted(deleted) {
      if (!deleted) {
        return;
      }
      // custom watcher
      if (this.deleteWatcher) {
        this.deleteWatcher(deleted)
      } else {
        this.$router
          .push({ name: `${this.$options.servicePrefix}List` })
          .catch(() => {
          });
      }

      if (this.afterDelete) {
        this.afterDelete();
      }
    },

    error(message) {
      message && this.showError(message);
    },

    deleteError(message) {
      message && this.showError(message);
    },

    updated(val) {
      if (!this.disableUpdateMessage) {
        if (this.updateMessage) {
          this.showMessage(this.updateMessage);
        } else {
          this.showMessage(`${val['@id']} updated.`);
        }
      }
      if (this.updateRoute) {
        this.$router
          .push(this.updateRoute)
          .catch(() => {
          });
      }
      if (this.afterUpdate) {
        this.afterUpdate();
      }
    },
    retrieved(val) {
      this.item = { ...val };
    }
  }
};
