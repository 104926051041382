import {mapFields} from 'vuex-map-fields';
import {mapActions, mapGetters} from 'vuex';
import {ENTRYPOINT} from '@/config/entrypoint';
import utilFetch from "@/utils/fetch";
import NotificationMixin from './NotificationMixin';

const entryPoint = ENTRYPOINT + (ENTRYPOINT.endsWith('/') ? '' : '/');

export default {
  data() {
    return {
      loadingDocuments: [],
    };
  },
  mixins: [NotificationMixin],
  computed: {
    ...mapFields('document', {
      document: 'current',
      uploadedDocument: 'created',
      documentIds: "allIds",
      documentIdsCached: "cacheAllIds",
    }),
    ...mapGetters("document", {
      findDocument: "find",
    }),
    documentUrl() {
      return entryPoint + this.document.contentUrl;
    },
    documentEntryPoint() {
      return entryPoint;
    }
  },
  methods: {
    ...mapActions('document', {
      upload: 'createFormData',
      retrieveDocument: 'load',
      deleteDocument: 'del',
    }),
    uploadDocument(document, isPrivate = false, version = '1', section = 'default') {
      var formData = new FormData();
      formData.append('name', document.name);
      formData.append('version', version);
      formData.append('private', ((isPrivate) ? 1 : 0).toString());
      formData.append('section', section);
      formData.append('file', document, document.name);
      return this.upload(formData);
    },
    downloadDocument(contentUrl, fileName, notify = false) {
      utilFetch(contentUrl, {
        method: 'GET'
      }).then(response => response.blob())
        .then(blob => {
            const data = window.URL.createObjectURL(blob)
            let a = document.createElement("a");
            a.href = data;
            a.download = fileName;
            a.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(data);
            }, 100);
          },
          error => {
            notify === true ? this.showError(error) : console.log("error!: " + error);
          }
        );
    },
    resolveDocument(itemId) {
      if (!this.isDocumentLoaded(itemId) && !this.loadingDocuments.includes(itemId)) {
        this.loadingDocuments.push(itemId);
        this.retrieveDocument(decodeURIComponent(itemId));
      }
      return this.findDocument(itemId);
    },
    resolveDocuments(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveDocument(itemId);
      }).filter(e => e);
    },
    isDocumentLoaded(itemId) {
      return this.documentIdsCached.includes(itemId);
    },
    getAndDownloadDocument(itemId) {
      const poll = resolve => {
        if (this.isDocumentLoaded((itemId))) {
          let document = this.resolveDocument(itemId);
          if(document.contentUrl.includes('media')){
            // Public file
            window.open(ENTRYPOINT + document.contentUrl, '_blank');
          }else{
            this.downloadDocument(document.contentUrl, document.name);
          }
          resolve();
        } else {
          this.resolveDocument(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    getDocumentItem(itemId, type) {
      if (itemId !== null) {
        let item = this.resolveDocument(itemId);
        if (item) {
          return item[type];
        }
      }
      return '';
    },
    getAndDownloadConfirmDocument(itemId) {
      const poll = resolve => {
        if (this.isDocumentLoaded((itemId))) {
          this.resolveDocument(itemId);
          resolve();
        } else {
          this.resolveDocument(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
  }
};
